import React from "react";
import { FaFacebook } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";

function Footer({ user }) {
  return (
    <>
      <footer>
        <section className="seccionfooter back__footer_negro">
          <div className="container">
            <div className="row">
              {/* footer */}
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 text-center">
                    <a href="/" className="logo__footer">
                      {" "}
                      <img src={user.logo} alt="" />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <h3 className="footer__title-h3">
                      Agencia de viajes en {user.city_name}, {user.state_name}:{" "}
                      {user.name_agency}
                    </h3>
                    <p className="footer__p">
                      {user.address}, {user.outdoor_number}, {user.suburb} CP.{" "}
                      {user.postal_code}
                    </p>
                  </div>
                </div>
              </div>

              {/**footer para atentas viajes  
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3 className="footer__title-h3">MATRIZ</h3>
                                        <p className="footer__p">
                                            {user.address}, {user.outdoor_number}<br/>
                                            Col: {user.suburb} - CP. {user.postal_code}<br/>
                                            {user.city_name}, {user.state_name}<br/>
                                            Tel. Oficina: <a href={'tel:' + user.phone}>{user.phone}</a><br/>
                                            <a href={'mailto:' + user.agency_mail}>{user.agency_mail}</a>
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <h3 className="footer__title-h3">SUCURSAL CHOLULA</h3>
                                        <p className="footer__p">
                                            Carril a Morillotla, 5 <br/>
                                            Col: Emiliano Zapata - CP. 72824<br/>
                                            San Andrés Cholula, Puebla<br/>
                                            Tel. Oficina: <a href='tel:2228821991'>2228821991</a><br/>
                                            <a href='mailto:viajesatenas.sucursal@gmail.com'>viajesatenas.sucursal@gmail.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                           */}
              <div className="col-md-6">
                <ul className="list-inline text-center list__ul-footer">
                  {user.facebook != null ? (
                    <li className="list-inline-item">
                      <a
                        href={"https://www.facebook.com/" + user.facebook}
                        target="_blank"
                        className="link__networks-footer"
                      >
                        <FaFacebook size={25} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {user.whatsapp != null ? (
                    <li className="list-inline-item">
                      <a
                        href={
                          "https://api.whatsapp.com/send?phone=52" +
                          user.phone +
                          "&amp;text=" +
                          user.name_agency +
                          ""
                        }
                        target="_blank"
                        className="link__networks-footer"
                      >
                        <FaWhatsapp size={25} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {user.twitter != null ? (
                    <li className="list-inline-item">
                      <a
                        href={"https://twitter.com/" + user.twitter}
                        target="_blank"
                        className="link__networks-footer"
                      >
                        <FaXTwitter size={25} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {user.instagram != null ? (
                    <li className="list-inline-item">
                      <a
                        href={"https://www.instagram.com/" + user.instagram}
                        target="_blank"
                        className="link__networks-footer"
                      >
                        <FaInstagram size={25} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
          {/* 
          <a
            href={
              "https://api.whatsapp.com/send?phone=52" +
              user.whatsapp +
              "&amp;text=" +
              user.name_agency +
              ""
            }
            target="_blank"
          >
            <div className="writing"> Escríbenos por WhatsApp </div>
            <img
              className="icon-whats"
              src="https://exodus.b-cdn.net/exodus/magno/03/img/whatsapp.png"
            />
          </a>
          */}
        </section>
      </footer>
    </>
  );
}
export default Footer;
